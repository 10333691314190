<script setup lang="ts">
import { SpeedInsights } from "@vercel/speed-insights/nuxt";
import { localeUrlRegex } from "~~/helpers/urls";

const { locale } = useI18n();
const route = useRoute();
const isLocaleInPath = localeUrlRegex.test(route.path);

useHead({
  noscript: [
    {
      children: `<meta http-equiv="refresh" content="0; url=${
        isLocaleInPath ? "/" + locale.value : ""
      }/no-script-error">`,
    },
  ],
});
</script>

<template>
  <div class="bg-white max-w-screen-xl mx-auto">
    <TheHeader />
    <main>
      <slot></slot>
    </main>
    <TheFooter />
  </div>
  <SpeedInsights />
</template>
